import { type AnimationProps } from "framer-motion"

import { type ListItem } from "@/types/ListItem"

import { type BaseDefaultPageParams } from "./types/globals"
import { getCacheKey } from "./utils/misc"

export const BUILD_ENV = process.env.NEXT_PUBLIC_BUILD_ENV ?? "production"

export const IS_BROWSER = typeof window !== "undefined"
export const IS_DEVELOPMENT = process.env.NODE_ENV === "development"
export const IS_PRODUCTION = process.env.NODE_ENV === "production"
export const IS_TEST = process.env.NODE_ENV === "test"

// TODO: deprecate all of the following since we're not using the local storage caching anymore:
export const AUTH_TOKEN_HEADER = "X-PHIDATA-AUTH-TOKEN"
export const AUTH_TOKEN_KEY = "phi-session"
export const AVAILABLE_WS_DATA_KEY = "available-ws"
export const CUSTOMER_DATA_KEY = "customer"
export const GITHUB_STATE_KEY = "gh-state"
export const POST_TO_CLI_KEY = "post-to-cli"
export const PRIMARY_WS_DATA_KEY = "primary-ws"
export const TEAM_MEMBERS_KEY = "team-members"
export const TEAM_USERS_KEY = "team-users"
export const TEAM_PLANS_KEY = "team-plans"
export const TEAM_WORKSPACES_KEY = "team-workspaces"
export const TEAMS_DATA_KEY = "teams"
export const URL_PARAMS_KEY = "url-params"
export const USER_ACTIVITY_KEY = "user-activity"
export const USER_DATA_KEY = "user"
export const USER_PLAN_KEY = "user-plan"
export const USER_ASSISTANT_RUNS_KEY = "user-assistant-runs"
export const USER_ASSISTANT_RUNS_COUNT_KEY = "user-assistant-runs-count"
export const WORKSPACE_ASSISTANT_RUNS_KEY = "workspace-assistant-runs"
export const WORKSPACE_ASSISTANT_RUNS_COUNT_KEY =
  "workspace-assistant-runs-count"
export const ASSISTANT_EVENTS_KEY = "assistant-run-events"
export const ASSISTANT_EVENT_SCORES = "assistant-run-event-scores"
export const REACT_QUERY_CACHE_KEY = "react-query-cache"

interface CacheKeyParams {
  workspace: string
  account: string
  id: string
}

interface Workspace {
  workspace_url: string
  team_url: string | undefined
}

export const CACHE_KEYS = {
  AVAILABLE_WORKSPACES: "available-workspaces",
  TEAM_WORKSPACES: "team-workspaces",
  WORKSPACES: "workspaces",
  TEAMS: "teams",
  ALL_RUNS: (params: BaseDefaultPageParams) =>
    getCacheKey("runs", [params.workspace]),
  RUN: (params: BaseDefaultPageParams) =>
    getCacheKey("runs", [params.workspace, params.id]),
  MONITOR_RUNS: (params: BaseDefaultPageParams) =>
    getCacheKey("monitor-runs", [params.workspace, params.account]),
  MONITOR_TOKENS: (params: BaseDefaultPageParams) =>
    getCacheKey("monitor-tokens", [params.workspace, params.account]),
  MONITOR_USERS: (params: BaseDefaultPageParams) =>
    getCacheKey("monitor-users", [params.workspace, params.account]),
  MONITOR_FEEDBACK: (params: BaseDefaultPageParams) =>
    getCacheKey("monitor-feedback", [params.workspace, params.account]),
  MONITOR_HALLUCINATIONS: (params: BaseDefaultPageParams) =>
    getCacheKey("monitor-hallucinations", [params.workspace, params.account]),
  DASHBOARD_RUNS: (params: BaseDefaultPageParams) =>
    getCacheKey("dashboard-runs", [params.workspace, params.account]),
  DASHBOARD_TOKENS: (params: BaseDefaultPageParams) =>
    getCacheKey("dashboard-tokens", [params.workspace, params.account]),
  DASHBOARD_USERS: (params: BaseDefaultPageParams) =>
    getCacheKey("dashboard-users", [params.workspace, params.account]),
  DASHBOARD_SESSIONS: (params: BaseDefaultPageParams) =>
    getCacheKey("dashboard-sessions", [params.workspace, params.account]),
  LATEST_ASSISTANT_RUN: "latest-assistant-run",
  PLAYGROUND_ENDPOINT: (workspace: Workspace) =>
    getCacheKey("playground-endpoint", [
      workspace.workspace_url,
      workspace.team_url,
    ]),
  PLAYGROUND_AGENT: ({ endpoint }: { endpoint: string }) =>
    getCacheKey("playground-agent", [endpoint]),

  USER: "user",
}

export const COOKIES = {
  USER_TIMEZONE: "user-timezone",
}

export const MemberPermissions: ListItem[] = [
  {
    name: "Read",
    value: "read",
  },
  {
    name: "Review",
    value: "review",
  },
  {
    name: "Edit",
    value: "edit",
  },
  {
    name: "Admin",
    value: "admin",
  },
]

export const CONTACT_SALES_LINK = "https://cal.com/phidata/intro"
export const CONTACT_SUPPORT_LINK = CONTACT_SALES_LINK

export const NAVIGATION_ANIMATION_DURATION = 0.3

export const HEADER_ANIMATION_PROPS: AnimationProps = {
  initial: { opacity: 0, translateY: 5 },
  animate: { opacity: 1, translateY: 0 },
  exit: { opacity: 0, translateY: -5 },
  transition: { duration: NAVIGATION_ANIMATION_DURATION },
}
